
import { defineComponent, PropType } from 'vue';
import deviceDetail from '@/components/view/common/device-info/index.vue';

export default defineComponent({
    components: {
        deviceDetail
    },
    props: {
        type: {
            type: String as PropType<ProjectType>,
            default: 'community'
        },
        deviceType: {
            type: String as PropType<'normal' | 'yale'>,
            default: 'normal'
        }
    }
});
